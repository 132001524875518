/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../helpers'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/inicio'
        icon='/media/icons/duotune/art/art002.svg'
        title="Início"
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItemWithSub to='' icon='/media/icons/duotune/art/art002.svg' title='componente teste' >
        <AsideMenuItem
          to='/componente_teste'
          icon='/media/icons/duotune/art/art002.svg'
          title="componente teste"
          fontIcon='bi-app-indicator'
        />
      </AsideMenuItemWithSub>
    </>
  )
}
