/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useLayout } from '../core'
import { KTSVG, toAbsoluteUrl } from '../../helpers'

const Footer: FC = () => {
  const { classes } = useLayout()
  return (
    <div className='footer py-4 d-flex flex-lg-column justify-content-between' id='kt_footer'>
      {/* begin::Container */}
      <div
        className={`${classes.footerContainer} d-flex align-items-center justify-content-center`}
      >
        {/* begin::Copyright */}
        {/* <div>
          <span className='text-muted fw-bold me-2'>{new Date().getFullYear()} &copy;</span>
          <a href='#' className='text-muted fw-bold me-2'>
            COTIN - Todos os direitos reservados
          </a>
        </div> */}
        <div className='text-dark order-2 order-md-1'>
          <img
            alt='Logo'
            className=''
            src={toAbsoluteUrl('/media/logos/Group 11.svg')}
          />
          <img
            alt='Logo'
            className=''
            src={toAbsoluteUrl('/media/logos/Logo-GOv-SEFAZ.svg')}
          />
        </div>
       
        {/* end::Copyright */}

        {/* begin::Nav */}

        {/* end::Nav */}
      </div>
      <div>
        teste
      </div>
      {/* end::Container */}
    </div>
  )
}

export { Footer }
