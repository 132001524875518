import React, { Suspense, lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { FallbackView } from '../../_cotin/partials'

export function PrivateRoutes() {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const DashboardPageWrapper = lazy(() => import('../pages/dashboard/DashboardPageWrapper'))
  const ComponenteTesteWrapper = lazy(() => import('../pages/componente-teste/ComponentePageWrapper'))

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/builder' component={BuilderPageWrapper} />
        <Route path='/inicio' component={DashboardPageWrapper} />
        <Route path='/componente_teste' component={ComponenteTesteWrapper} />
        <Redirect from='/auth' to='/inicio' />
        <Redirect exact from='/' to='/inicio' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
